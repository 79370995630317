import { call, put, takeEvery } from "redux-saga/effects";
import { getAuthUserDetails } from "../../utils/Helpers";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  ChecklistLoading,
  submitChecklistActionDispatcher,
  submitChecklistErrorDispatcher,
  SUBMIT_CHECKLIST_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postEventAPI = async () => {
  let cid =
    localStorage.getItem("defPortal") === "USOB"
      ? getAuthUserDetails()?.usob_cid
      : getAuthUserDetails().CustomerId;
  let src =
    localStorage.getItem("defPortal") === "USOB"
      ? "PWA"
      : "Uptime";
  const result = await AXIOS_INSTANCE.get(
    getAuthUserDetails()?.IsAdmin
      ? "/checklist?source="+src
      : "/checklist?source="+src+"&customer_id=" + cid
    // { customerid: cid }
    // getAuthUserDetails()?.IsAdmin ? "/event" :
  );
  // console.log("result: ", result);
  return result;
};

function* submitEventSaga({ postData }) {
  try {
    yield put(ChecklistLoading(true));
    const data = yield call(postEventAPI, postData);
    yield put(submitChecklistActionDispatcher(data));
    yield put(submitChecklistErrorDispatcher({}));
    yield put(ChecklistLoading(false));
  } catch (e) {
    yield put(submitChecklistActionDispatcher({}));
    yield put(
      submitChecklistErrorDispatcher(e.response.data, console.log(e.response))
    );
    yield put(ChecklistLoading(false));
  }
}

function* submitEventWatcher() {
  yield takeEvery(SUBMIT_CHECKLIST_ACTION_REQUESTED, submitEventSaga);
}
export default submitEventWatcher;
