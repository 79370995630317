import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBOpenJobsByTypeLoading,
  submitUSOBOpenJobsByTypeActionDispatcher,
  submitUSOBOpenJobsByTypeErrorDispatcher,
  SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBOpenJobsByTypeAPI = async (postData, filters) => {
  // console.log("filters: ", filters);
  // console.log("postData: ", postData);
  let api =
    "/dashboard/open-jobs?pagination=false" +
    (postData.repairCategoryID
      ? "&repair_category_id=" + postData.repairCategoryID
      : "") +
    (postData.category === "unassigned"
      ? postData.reasonID
        ? "&repair_ids=" + postData.reasonID
        : ""
      : postData.reasonID
      ? "&reason_ids=" + postData.reasonID
      : "") +
    (filters?.segment ? "&segment=" + filters?.segment : "") +
    (filters?.vehicle_type ? "&vehicle_type=" + filters?.vehicle_type : "") +
    (filters?.customer_id ? "&customer_id=" + filters?.customer_id : "") +
    (filters?.vehicle_group ? "&vehicle_group=" + filters?.vehicle_group : "");
  const result = await AXIOS_INSTANCE.get(api)
    .then((response) => {
      let result = response.request.responseText;
      const resultX = JSON.parse(result.replace(/:\s*(-?\d+),/g, ': "$1",'));
      return resultX;
    })
    .catch((err) => {
      console.log("err: ", err);
    });
  // console.log("result: ", result);
  return { data: result, category: postData.category };
};

function* submitUSOBOpenJobsByTypeSaga({ postData, filters }) {
  try {
    yield put(USOBOpenJobsByTypeLoading(true));
    const data = yield call(postUSOBOpenJobsByTypeAPI, postData, filters);
    yield put(submitUSOBOpenJobsByTypeActionDispatcher(data));
    yield put(submitUSOBOpenJobsByTypeErrorDispatcher({}));
    yield put(USOBOpenJobsByTypeLoading(false));
  } catch (e) {
    yield put(submitUSOBOpenJobsByTypeErrorDispatcher({}));
    yield put(submitUSOBOpenJobsByTypeErrorDispatcher(e, console.log(e)));
    yield put(USOBOpenJobsByTypeLoading(false));
  }
}

function* submitUSOBOpenJobsByTypeWatcher() {
  yield takeEvery(
    SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ACTION_REQUESTED,
    submitUSOBOpenJobsByTypeSaga
  );
}
export default submitUSOBOpenJobsByTypeWatcher;
