import moment from "moment";
import {
  CHECKLIST_LOADING,
  SUBMIT_CHECKLIST_ACTION_DISPATCHER,
  SUBMIT_CHECKLIST_ACTION_REQUESTED,
  SUBMIT_CHECKLIST_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { checklist: [], excelData: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const checkList = [];
  let tempExcelArr = [];
  switch (action.type) {
    case SUBMIT_CHECKLIST_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_CHECKLIST_ACTION_DISPATCHER:
      let data = action.payload.data?.success;
      data?.checklistData?.forEach((e) => {
        console.log(
          'moment(e.created_at).format("DD MMM YY hh:mm a"): ',
          moment(e.created_at).format("DD MMM YY hh:mm a")
        );
        console.log(
          'moment(e.created_at).format("YYYY-MM-DD"): ',
          moment(e.created_at).format("YYYY-MM-DD")
        );
        let checklist = {
          title: e.checkup_type_name,
          start: moment(e.created_at).format("YYYY-MM-DD") + " 09:00:00",
          end: moment(e.created_at).format("YYYY-MM-DD") + " 18:00:00",
          textColor: "#FFFFFF",
          extendedProps: {
            id: e.id,
            checkList: true,
            textColor: "#FFFFFF",
            bgColor: e.color,
            borderColor: e.color,
            startTime: "09:00 am",
            endTime: "06:00 pm",
            milage: e.milage,
            runningType: e.running_type,
            addedBy: e.added_by,
            createAt: moment(e.created_at).format("DD MMM YY hh:mm a"),
            engineHrs: e.engine_hours,
            model: e.model_name,
            start: moment(e.created_at).format("DD MMM YY hh:mm a"),
            end: moment(e.created_at).format("DD MMM YY hh:mm a"),
            okQues: e.questions.okQuestions,
            notOkQues: e.questions.notOKQuestions,
            naQues: e.questions.naQuestions,
            chassisID: e.chassis_number,
            type: e.checkup_type_name,
            custName: e.customer_name,
            area: e.area,
            attachments: e.imageUrls,
            region: e.region_name,
          },
          description: e.description,
        };
        let checklistGrpCount = checkList.length;
        let checklistGrp = checkList.find(
          (obj) =>
            obj.extendedProps.type === e.checkup_type_name &&
            obj.start ===
              moment(e.created_at).format("YYYY-MM-DD") + " 09:00:00" &&
            obj.end === moment(e.created_at).format("YYYY-MM-DD") + " 18:00:00"
        );
        if (checklistGrp) {
          checklistGrp.extendedProps.events.push(checklist);
          checklistGrp.extendedProps.eventsCount += 1;
        } else {
          checkList.push({
            title: e.checkup_type_name,
            start: moment(e.created_at).format("YYYY-MM-DD") + " 09:00:00",
            end: moment(e.created_at).format("YYYY-MM-DD") + " 18:00:00",
            textColor: "#FFFFFF",
            extendedProps: {
              id: checklistGrpCount,
              checkList: false,
              start: moment(e.start).format("MMMM D, YYYY"),
              textColor: "#FFFFFF",
              bgColor: e.color,
              borderColor: e.color,
              type: e.checkup_type_name,
              status: e.status,
              events: [checklist],
              eventsCount: 1,
            },
          });
        }
        // checkList.push(checklist);
        tempExcelArr.push([
          e.checkup_type_name,
          e.customer_name,
          e.chassis_number + "-" + e.checkup_type_name,
          moment(e.created_at).format("YYYY-MM-DD") + " 09:00:00",
          moment(e.created_at).format("YYYY-MM-DD") + " 18:00:00",
          e.status,
          e.checkup_type_name,
          e.model_name,
          e.chassis_number,
          e.workshop_name,
          e.area_name,
          e.region_name,
        ]);
      });
      // console.log("checkList: ", checkList);
      return {
        ...state,
        successData: {
          checklist: checkList,
          excelData: tempExcelArr,
          apiData: data,
        },
        loading: false,
      };
    case SUBMIT_CHECKLIST_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case CHECKLIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
