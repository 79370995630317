import {
  CHECKUP_TYPES_LOADING,
  SUBMIT_CHECKUP_TYPES_ACTION_DISPATCHER,
  SUBMIT_CHECKUP_TYPES_ACTION_REQUESTED,
  SUBMIT_CHECKUP_TYPES_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { checkupTypes: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_CHECKUP_TYPES_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_CHECKUP_TYPES_ACTION_DISPATCHER:
      const checkupTypes = [];
      // console.log('action.payload.data: ', action.payload.data);
      action.payload.data?.success?.checkupType?.forEach((e) => {
        checkupTypes.push({ type: e.checkup_type, color: e.color });
      });
      return {
        ...state,
        successData: {
          checkupTypes: checkupTypes,
          apiData: action.payload.data,
        },
        loading: false,
      };
    case SUBMIT_CHECKUP_TYPES_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case CHECKUP_TYPES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
