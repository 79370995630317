export const ROUTERS = {
  LOGIN: "/login",
  SSO_LOGIN: "/sso-login",
  // LAUNCH_PAGE: "/launch",
  // UPTIME_SERVICES_PAGE: "/volvo-uptime-services",
  FORGOT_PASSWORD: "/forgot-password",
  FLEET_SUMMARY: "/vas/fleet-summary",
  FUEL_EFFICIENCY: "/vas/fuel-efficiency",
  UPTIME_UTILIZATION: "/vas/uptime-utilization",
  TRIP_SUMMARY: "/vas/trip-summary",
  LATEST_NEWS: "/vas/latest-news",
  MY_SITES: "/vas/my-sites",
  SITE_ADVISORY: "/vas/site-advisory",
  MY_ASSETS: "/vas/my-assets",
  SERVICE_HISTORY: "/vas/service-history",
  CONTACT_US: "/vas/contact-us",
  FLEET_MANAGEMENT: "/vas/fleet-management",
  CUSTOMER_PROFILE: "/vas/customer-profile",
  EDIT_PROFILE: "/vas/edit-profile",
  CHANGE_PASSWORD: "/vas/change-password",
  MY_SHIFTS: "/vas/my-shifts",
  EDIT_SHIFT: "/vas/edit-shift",
  INSERT_SHIFT: "/vas/insert-shift",
  VAS_PB_BUSES: "/vas/product/bus-brochures",
  VAS_PB_TRUCKS: "/vas/product/truck-brochures",
  REGISTER_COMPLAINT: "/vas/register-complaint",
  VEHICLE_GROUPS: "/vas/vehicle-groups",
  VEHICLE_GROUP_DETAILS: "/vas/vehicle-group-details",
  EDIT_VEHICLE_GROUP: "/vas/edit-vehicle-group",
  CREATE_VEHICLE_GROUP: "/vas/create-vehicle-group",
  MY_CALENDAR: "/vas/my-calendar",
  //usob
  USOB_DASHBOARD: "/usob/dashboard",
  USOB_FUEL_EFFICIENCY: "/usob/fuel-efficiency",
  USOB_LATEST_NEWS: "/usob/latest-news",
  USOB_MY_ASSETS: "/usob/my-assets",
  USOB_SERVICE_HISTORY: "/usob/service-history",
  USOB_ACTION_SERVICE_HISTORY: "/usob/action-service-history",
  USOB_WORKSHOP_SERVICE_HISTORY: "/usob/workshop-service-history",
  USOB_CONTACT_US: "/usob/contact-us",
  USOB_CUSTOMER_PROFILE: "/usob/customer-profile",
  USOB_EDIT_PROFILE: "/usob/edit-profile",
  USOB_CHANGE_PASSWORD: "/usob/change-password",
  USOB_PB_BUSES: "/usob/product/bus-brochures",
  USOB_PB_TRUCKS: "/usob/product/truck-brochures",
  USOB_REGISTER_COMPLAINT: "/usob/register-complaint",
  USOB_ACTION_SERVICE_REQUESTS: "/usob/action-services",
  USOB_SERVICE_REQUEST_DETAILS: "/usob/action-service-details",
  USOB_REGISTER_ACTION_SERVICE: "/usob/register-action-service",
  USOB_TRACKING_ACTION_SERVICE: "/usob/tracking-action-service",
  USOB_DEALER_LOCATOR: "/usob/dealer-locator",
  USOB_WORKSHOP_SERVICES: "/usob/workshop-services",
  USOB_WORKSHOP_SERVICE_DETAILS: "/usob/workshop-service-details",
  USOB_REGISTER_WORKSHOP_SERVICE: "/usob/register-workshop-service",
  USOB_VEHICLE_GROUPS: "/usob/vehicle-groups",
  USOB_VEHICLE_GROUP_DETAILS: "/usob/vehicle-group-details",
  USOB_EDIT_VEHICLE_GROUP: "/usob/edit-vehicle-group",
  USOB_CREATE_VEHICLE_GROUP: "/usob/create-vehicle-group",
  USOB_MY_CALENDAR: "/usob/my-calendar",
};
