import axios from "axios";
import { getAuthUserDetails } from "./Helpers";
import USOBApiRequest from "./ApiUrls";
const LosslessJSON = require("lossless-json");
/**
 * @class Service
 * @description It sets up the entire axios services and Auth token
 * @param {*}
 * @return {*}
 */

// convert Decimal to LosslessNumber
function replacer(key, value) {
  if (value && value.isLosslessNumber) {
    if (value.value.length > 15) {
      return `${value}`;
    } else {
      return parseInt(value);
    }
  } else {
    return value;
  }
}
class Service {
  constructor() {
    this.get = (apiName, header = {}, cancelToken, config = {}) => {
      return axios.get(USOBApiRequest.USOBApiBaseURL + apiName, {
        headers: {
          "Content-Type": "text/plain",
          userid: getAuthUserDetails()?.usob_id,
          customerid: getAuthUserDetails()?.usob_cid,
          roleid: getAuthUserDetails()?.usob_rid,
          ...header,
        },
        cancelToken,
        ...config,
        transformResponse: (x) => {
          const cc = LosslessJSON.parse(x, replacer);
          return cc;
        },
      });
    };

    this.post = (apiName, postData, header = {}, cancelToken, config = {}) => {
      return axios.post(
        USOBApiRequest.USOBApiBaseURL + apiName,
        { ...postData },
        {
          headers: {
            "Content-Type": "application/json",
            userid: getAuthUserDetails()?.usob_id,
            customerid: getAuthUserDetails()?.usob_cid,
            roleid: getAuthUserDetails()?.usob_rid,
            ...header,
          },
          cancelToken,
          ...config,
        }
      );
    };

    this.put = (apiName, postData, header = {}, config = {}) => {
      return axios.put(USOBApiRequest.USOBApiBaseURL + apiName, postData, {
        headers: {
          "Content-Type": "application/json",
          userid: getAuthUserDetails()?.usob_id,
          customerid: getAuthUserDetails()?.usob_cid,
          roleid: getAuthUserDetails()?.usob_rid,
          ...header,
        },
        ...config,
      });
    };

    this.delete = (apiName, header = {}, config = {}) => {
      return axios.delete(USOBApiRequest.USOBApiBaseURL + apiName, {
        headers: {
          "Content-Type": "application/json",
          userid: getAuthUserDetails()?.usob_id,
          customerid: getAuthUserDetails()?.usob_cid,
          roleid: getAuthUserDetails()?.usob_rid,
          ...header,
        },
        ...config,
      });
    };
  }
}
// eslint-disable-next-line
export default new Service();
