import { call, put, takeEvery } from "redux-saga/effects";
// import AXIOS_INSTANCE from "../../utils/Config";

import {
  ssoLoginLoading,
  submitSSOLoginActionDispatcher,
  submitSSOLoginErrorDispatcher,
  SUBMIT_SSO_LOGIN_ACTION_REQUESTED,
} from "./Action"; // Path should come here
import axios from "axios";

const postSSOLoginAPI = async (postData) => {
  const result = await axios.post(
    // "https://volvo-sso.siplsolutions.com/verify-sso-token",
    "http://10.10.11.73/volvo-sso/public/verify-sso-token",
    {
      ...postData,
    }
  );
  // console.log("result: ", result);
  // if(result.data.)
  return result;
};

function* submitSSOLoginSaga({ postData }) {
  try {
    yield put(ssoLoginLoading(true));
    const data = yield call(postSSOLoginAPI, postData);
    yield put(submitSSOLoginActionDispatcher(data));
    yield put(submitSSOLoginErrorDispatcher({}));
    yield put(ssoLoginLoading(false));
  } catch (e) {
    yield put(submitSSOLoginActionDispatcher({}));
    yield put(submitSSOLoginErrorDispatcher(e.response.data));
    yield put(ssoLoginLoading(false));
  }
}

function* submitSSOLoginWatcher() {
  yield takeEvery(SUBMIT_SSO_LOGIN_ACTION_REQUESTED, submitSSOLoginSaga);
}
export default submitSSOLoginWatcher;
