import React, { lazy, Suspense } from "react";
import { Layout } from "antd";
import { Spin } from "antd";
import { isUserLoggedIn } from "./utils/Helpers";

const Routers = lazy(() => import("./components/routers"));
const defPortal = localStorage.getItem("defPortal");
const Header =
  defPortal === "USOB"
    ? lazy(() => import("../src/components/usob/header/Header"))
    : lazy(() => import("../src/components/tippers/header/Header"));

const App = () => {
  return (
    <Suspense
      fallback={
        <center>
          <div className="initial-loading">
            <Spin size="large" tooltip="Loading..." style={{ paddingTop: 400 }} />
          </div>
        </center>
      }
    >
      <Layout className="layout">
        {isUserLoggedIn() && <Header />}
        <Routers />
      </Layout>
    </Suspense>
  );
};

export default App;
